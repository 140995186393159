exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-multi-surface-cleaner-js": () => import("./../../../src/pages/multi-surface-cleaner.js" /* webpackChunkName: "component---src-pages-multi-surface-cleaner-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-refill-pack-js": () => import("./../../../src/pages/refill-pack.js" /* webpackChunkName: "component---src-pages-refill-pack-js" */),
  "component---src-pages-safe-data-js": () => import("./../../../src/pages/safe-data.js" /* webpackChunkName: "component---src-pages-safe-data-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-spot-cleaner-pen-js": () => import("./../../../src/pages/spot-cleaner-pen.js" /* webpackChunkName: "component---src-pages-spot-cleaner-pen-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

